/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import React, { useState, useEffect, memo } from "react";
import { Row } from "antd";
import Image from "next/image";
import styles from "@aspen/theme/Vip.module.less";
import { PrivilegesTable } from "@aspen/widgets";
import {
  handleLevel,
  transformDate,
  i18nUtil,
  decimalPointNoMoreX,
  numberToThousands,
  CUSTOMER_INFO,
  MEMBER_INFO,
  USER,
  BENCHMARK_CURRENCY_SYMBOL,
  VIP_ICON_INFO
} from "@aspen/libs";
import { memberAUM } from "@aspen/services";
import { EVipType } from "@aspen/model";

import ic_avatar from "@aspen/assets/images/vip/ic_avatar.png";

function Vip() {
  const intl = i18nUtil.t();
  const customerInfo = typeof window != "undefined" && sessionStorage.getItem(CUSTOMER_INFO);
  const memberInfo =
    typeof window != "undefined" && JSON.parse(localStorage.getItem(MEMBER_INFO) ?? "");
  const kycName: string | number = (customerInfo && JSON.parse(customerInfo)?.kycName) ?? "";
  const currentEmail = typeof window != "undefined" && sessionStorage.getItem(USER);
  const registerName: string | number =
    (customerInfo && JSON.parse(customerInfo)?.registerName) ?? "";
  const currentVipInfo = VIP_ICON_INFO[handleLevel(memberInfo?.vipLevel)];
  const MEMBER_VIP_INFO = currentVipInfo ?? VIP_ICON_INFO[handleLevel("L0")];
  const [aumStr, setAumStr] = useState<string>("");
  const [currentLevel, setCurrentLevel] = useState<string>("");
  useEffect(() => {
    getMemberAUM();
    setCurrentLevel(handleLevel(memberInfo?.vipLevel));
  }, []);

  const getMemberAUM = async () => {
    const res = await memberAUM({ clacDats: 90 });
    if (res?.code == "0") {
      setAumStr(BENCHMARK_CURRENCY_SYMBOL + numberToThousands(decimalPointNoMoreX(res?.data, 2)));
    }
  };

  const handleShowDate = () => {
    let dateStr = transformDate(memberInfo?.vipEndTime);
    const handleStr = dateStr && dateStr.split("-");
    const showStr = handleStr.length && `${handleStr[1]}/${handleStr[2]}/${handleStr[0]}`;
    return `${intl["vip.exp"]} ${showStr}`;
  };

  return (
    <section className={`${styles.vip} ` + `customer`}>
      <section className="customerTop">
        <div className={`top ${styles.vipTop}`}>
          <p className="title">{intl["vip"]}</p>
        </div>
      </section>
      <Row gutter={16} className="section" style={{ flexDirection: "column", minHeight: 141 }}>
        {currentLevel ? (
          <section
            className={styles.module}
            style={{ backgroundImage: `url(${MEMBER_VIP_INFO?.bg})` }}>
            <div className={styles.row}>
              <div
                style={{
                  flexDirection: "column",
                  marginRight: 65,
                  marginLeft: 28,
                  height: "100%"
                }}>
                <div style={{ position: "relative", width: 93, height: 93 }}>
                  <Image
                    unoptimized
                    width={93}
                    height={93}
                    className={styles.avatarImg}
                    src={ic_avatar}
                    alt=""
                  />
                  <div className={styles.levelWrapper}>
                    <Image alt="" unoptimized width="28" height="28" src={MEMBER_VIP_INFO?.icon} />
                  </div>
                </div>
              </div>
              <div style={{ flexDirection: "column", width: "65%" }}>
                <p className={styles.name}>{kycName || registerName || currentEmail}</p>
                <span className={styles.descTxt}>{`${intl["vip.aum.title"]}${aumStr}`}</span>
              </div>
            </div>
            {handleLevel(memberInfo?.vipLevel) === "Silver" ? null : (
              <p className={styles.dateTxt}>{handleShowDate()}</p>
            )}
          </section>
        ) : null}
      </Row>
      <PrivilegesTable currentLevel={currentLevel ?? handleLevel(memberInfo?.vipLevel)} />
      <div className={styles.bottomQA}>
        <p>Q&A</p>
        <span>Q：{intl["vip.question"]}</span>
        <span>A：{intl["vip.ask"]}</span>
      </div>
    </section>
  );
}
export const PageVip = memo(Vip);
